import React from "react";
import { ReactComponent as Rock } from "../../assets/Rock.svg";

const SideTitle = ({ first, last, className = "", type = "default" }) => {
    if (!className.includes("font-")) {
        className += ` font-big`;
    }
    if (type === "default") {
        return (
            <div className={`font-w-bold clr-text-primary ${className}`}>
                <div className="relative group">
                    <span className="">
                        <span className="clr-text-primary smooth group-hover:text-sandy-500  dark:group-hover:text-sandy-500 saturate-50 ">
                            {first}{" "}
                        </span>
                        <span className="text-sandy-500 saturate-50 dark:text-sandy-500 group-hover:text-black dark:group-hover:text-white smooth">
                            {last}{" "}
                        </span>
                    </span>
                    <span className="absolute w-28 h-1 bg-secondary-container rounded-md right-0 group-hover:right-1/4 top-full smooth"></span>
                    <span className="absolute w-14 h-1 bg-secondary-container rounded-md right-0 top-full group-hover:right-1/3 transform translate-y-2 smooth"></span>
                    <span className="absolute w-14 h-1 bg-secondary-container rounded-md right-0 bottom-full group-hover:right-1/3 transform -translate-y-2 smooth"></span>
                </div>
            </div>
        );
    }
    if (type === "RockDecore") {
        return (
            <div className="group text-3xl relative sm:text-4xl md:text-5xl">
                <div className="flex justify-between w-full -mb-1">
                    <Rock className="-mb-1 w-[130px] opacity-80" />
                    <Rock className="-mb-3 opacity-80 w-[110px]" />
                </div>
                <span className="clr-text-primary smooth group-hover:text-sandy-500  dark:group-hover:text-sandy-500 saturate-50 ">
                    {first}
                </span>{" "}
                <span className="text-sandy-500 saturate-50 dark:text-sandy-500 group-hover:text-black dark:group-hover:text-white smooth">
                    {last}
                </span>
            </div>
        );
    }
};

export default SideTitle;
