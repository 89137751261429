import React from "react";

import NavbarLink from "../layout/navbar/components/NavbarLink";
import DisclosureLink from "../layout/navbar/components/DisclosureLink";
import logo from "../assets/navbar/logo.png";
import logoText from "../assets/navbar/logoText.png";
import CenterIcon from "../components/ui/CenterIcon";
import LogoSvg from "../components/svgs/LogoSvg";
import NavbarLogo from "../assets/LogoNav.png";
import NavbarLogoLight from "../assets/LogoNavLight.png";
import bones from "../assets/bones.png";
import skull from "../assets/skull.png";
export const navLogoComponent = (darkmode) => (
    <>
        {/* <img className="h-12 w-auto block lg:hidden" src={logo} alt="Workflow" /> */}
        {/* <div><LogoSvg className="w-28" /></div> */}
        <img
            className="h-auto w-14 sm:w-16"
            src={!darkmode ? NavbarLogoLight : NavbarLogo}
            alt="Workflow"
        />
        {/* <img className="h-auto w-14 sm:w-16" src={NavbarLogo} alt="Workflow" /> */}
    </>
);
export const navbarClassName = (isNavbarHidden, isRTL, scrolling) =>
    ` ${scrolling && "bg-primary-container"}`;
export const NavbarRegisterComponent = () => (
    <NavbarLink
        defualtHover="hover:bg-opacity-0 dark:hover:bg-opacity-0 hover:text-medhatBlue-900 dark:hover:text-medhatBlue-600 group dark:saturate-50"
        to="/register"
        className="bg-medhatBlue-900 dark:bg-medhatBlue-600 clr-white border-2 border-medhatBlue-900 dark:border-medhatBlue-600 group "
    >
        <span className="flex-center-both font-h2 smooth text-medhatBlue-400 group-hover:text-medhatBlue-900 dark:group-hover:text-medhatBlue-600">
            {/* <svg
                width={25}
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g opacity="0.8">
                    <path
                        d="M3.88889 11H2.5C2.03934 11 1.82377 10.4299 2.16919 10.1251L10.6692 2.62508C10.8582 2.45831 11.1418 2.45831 11.3308 2.62508L19.8308 10.1251C20.1762 10.4299 19.9607 11 19.5 11H18.1111V18C18.1111 18.2761 17.8873 18.5 17.6111 18.5H4.38889C4.11274 18.5 3.88889 18.2761 3.88889 18V11Z"
                        fill="currentColor"
                    />
                </g>
                <path
                    d="M0.999997 10H2.38889V17C2.38889 17.2761 2.61274 17.5 2.88889 17.5H16.1111C16.3873 17.5 16.6111 17.2761 16.6111 17V10H18C18.4607 10 18.6762 9.42986 18.3308 9.12508L9.83081 1.62508C9.6418 1.45831 9.3582 1.45831 9.16919 1.62508L0.669186 9.12508C0.323766 9.42986 0.539337 10 0.999997 10ZM2.88889 9H2.32238L9.5 2.66681L16.6776 9H16.1111C15.835 9 15.6111 9.22386 15.6111 9.5V16.5H3.38889V9.5C3.38889 9.22386 3.16503 9 2.88889 9Z"
                    className="smooth fill-slate-100 dark:group-hover:fill-slate-100 group-hover:fill-slate-900"
                />
                <path
                    d="M10.7083 11.5H8.20833C7.65604 11.5 7.20833 11.9477 7.20833 12.5V16.5C7.20833 17.0523 7.65604 17.5 8.20833 17.5H10.7083C11.2606 17.5 11.7083 17.0523 11.7083 16.5V12.5C11.7083 11.9477 11.2606 11.5 10.7083 11.5ZM8.20833 16.5V12.5H10.7083V16.5H8.20833Z"
                    className="smooth fill-slate-100 dark:group-hover:fill-slate-100 group-hover:fill-slate-900"
                />
            </svg> */}
            {/* <CenterIcon icon={"fluent-emoji-high-contrast:volcano"} /> */}
            <img src={bones} alt="bones" className="w-6 h-6" />
        </span>
        <span className="flex-center-both">انشئ حسابك !</span>
    </NavbarLink>
);
export const NavbarLoginComponent = ({ isRTL }) => (
    <NavbarLink
        to="/login"
        className="border border-sandy-500 border-opacity-0 text-sandy-700  font-w-bold"
        defualtHover="hover:dark:border-opacity-100  hover-shadow"
    >
        {/* <CenterIcon
            className="dark:text-sandy-500 text-sandy-500 smooth font-h2"
            icon={"fa6-solid:dungeon"}
        /> */}
        <img src={skull} alt="skull" className="w-6 h-6" />
        <span className="flex-center-both space-x-1 space-x-reverse font-w-bold">
            <span>تسجيل</span>
            <span className="dark:text-sandy-500 text-sandy-700 smooth">الدخول</span>
        </span>
    </NavbarLink>
);
export const DisclosureLoginComponent = ({ isRTL }) => (
    <DisclosureLink to="/login">
        <CenterIcon className="text-red-400 saturation-color font-h2" icon={"fa6-solid:dungeon"} />
        <span className="flex-center-both">الدخول إلى حسابك</span>
    </DisclosureLink>
);
export const DisclosureRegisterComponent = () => (
    <DisclosureLink to="/register">
        {/* <svg
            width={25}
            className={"text-emerald-500"}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.8">
                <path
                    d="M3.88889 11H2.5C2.03934 11 1.82377 10.4299 2.16919 10.1251L10.6692 2.62508C10.8582 2.45831 11.1418 2.45831 11.3308 2.62508L19.8308 10.1251C20.1762 10.4299 19.9607 11 19.5 11H18.1111V18C18.1111 18.2761 17.8873 18.5 17.6111 18.5H4.38889C4.11274 18.5 3.88889 18.2761 3.88889 18V11Z"
                    fill="currentColor"
                />
            </g>
            <path
                d="M0.999997 10H2.38889V17C2.38889 17.2761 2.61274 17.5 2.88889 17.5H16.1111C16.3873 17.5 16.6111 17.2761 16.6111 17V10H18C18.4607 10 18.6762 9.42986 18.3308 9.12508L9.83081 1.62508C9.6418 1.45831 9.3582 1.45831 9.16919 1.62508L0.669186 9.12508C0.323766 9.42986 0.539337 10 0.999997 10ZM2.88889 9H2.32238L9.5 2.66681L16.6776 9H16.1111C15.835 9 15.6111 9.22386 15.6111 9.5V16.5H3.38889V9.5C3.38889 9.22386 3.16503 9 2.88889 9Z"
                fill="var(--color-text-primary)"
                className="smooth"
            />
            <path
                d="M10.7083 11.5H8.20833C7.65604 11.5 7.20833 11.9477 7.20833 12.5V16.5C7.20833 17.0523 7.65604 17.5 8.20833 17.5H10.7083C11.2606 17.5 11.7083 17.0523 11.7083 16.5V12.5C11.7083 11.9477 11.2606 11.5 10.7083 11.5ZM8.20833 16.5V12.5H10.7083V16.5H8.20833Z"
                fill="var(--color-text-primary)"
                className="smooth"
            />
        </svg> */}
        {/* <UserAddIcon className="h-6 w-6 text-yellow-300" /> */}
        <CenterIcon
            className="font-h2 text-emerald-400"
            icon={"fluent-emoji-high-contrast:volcano"}
        />
        <span className="flex-center-both">انشئ حسابك الآن !</span>
    </DisclosureLink>
);

export const domainName = "mahmoud-magdy.com";
