import React from "react";
import { printShortDateTime } from "../../utils/time";

const AdminToAdminTagsTableCell = ({ user }) => {
    return (
        <div>
            {user.admin_to_admin_user_tags &&
                user.admin_to_admin_user_tags.map((note, index) => {
                    // return note.name + " ";

                    if (index > 2) return "";
                    return (
                        <div
                            key={note.id}
                            className="bg-slate-200 dark:bg-slate-800 smooth clr-text-primary mb-2 rounded-md py-1 font-smaller border border-cyan-500"
                            style={{ whiteSpace: "pre-wrap" }}
                        >
                            <div className="text-slate-900 dark:text-slate-100 smooth flex-center-both flex-wrap gap-1">
                                {note.admin_to_admin_user_tags.map((tag) => {
                                    return (
                                        <div
                                            key={tag.id}
                                            className="bg-cyan-500 text-white rounded-md px-3 py-1 shadow-md flex-"
                                        >
                                            {tag.name}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="flex-center-both px-4 py-2">
                                <div className="w-full h-0.5 rounded-md bg-primary-container smooth"></div>
                            </div>
                            {printShortDateTime(note.created_at)}
                        </div>
                    );
                })}
        </div>
    );
};

export default AdminToAdminTagsTableCell;
