import React, { useEffect, useState } from "react";
import InputField from "../../components/form/elements/InputField";
import http from "../../services/httpServices";
import auth from "../../services/authServices";
import modal from "../../services/modalServices";
import AdminContainer from "../../components/ui/AdminContainer";
import {
    handleFormErrors,
    handleFormSubmit,
    handleInputChange as handleChange,
    getFormData,
} from "../../services/formServices";
import AdminForm from "../../components/ui/AdminForm";
const initialState = {
    source: "",
};

const Testimonial = () => {
    const [data, setData] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const [inputFields, setInputFields] = useState([]);

    useEffect(() => {
        let fields = [
            {
                id: "source",
                placeholder: "الصورة",
                type: "file",
            },
        ];
        setInputFields(fields);
    }, [data.source]);

    const handleSubmit = (e) => {
        handleFormSubmit(e, setIsLoading, setErrors, () => {
            subscribeUser();
        });
    };
    const subscribeUser = async () => {
        try {
            const adminToken = auth.getAdminToken();
            const config = auth.getAdminAuthConfig(adminToken);

            // const toSendData = { ...data };
            const formData = getFormData(data);
            // toSendData["phone"] = parseInt(data["phone"]);
            const { data: response } = await http.post("/api/testimonial", formData, config);

            modal.message({
                title: "تم تنفيذ طلبك بنجاح",
                text: response.message,
            });
            setData(initialState);
            setIsLoading(false);
        } catch ({ response }) {
            handleFormErrors(response, setIsLoading, setErrors);
        }
    };

    return (
        <div className="space-y-10">
            <AdminContainer>
                <AdminForm onSubmit={handleSubmit} isLoading={isLoading} buttonText="اضافة">
                    {inputFields.map((input, key) => (
                        <InputField
                            key={key}
                            onChange={handleChange}
                            data={data}
                            setData={setData}
                            errors={errors}
                            {...input}
                        />
                    ))}
                </AdminForm>
            </AdminContainer>
        </div>
    );
};

export default Testimonial;
