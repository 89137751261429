import React, { useContext } from "react";

import profile from "../../assets/Einstein.png";
import Container from "../../components/ui/Container";
import FlexRowReverse from "../../components/ui/FlexRowReverse";

import time from "../../assets/burning-time.svg";
import calendar from "../../assets/calendar.svg";
import exam from "../../assets/contest.svg";
import Testimonials from "./Testimonials";
import LogoSvg from "../../components/svgs/LogoSvg";

import bglight from "../../assets/bg.png";
import bg from "../../assets/bg-light.png";
import ThemeContext from "../../context/ThemeContext";
import AuthContext from "../../context/AuthContext";
import Button from "../../components/ui/Button";
import Waves from "../../components/ui/Waves";
import CenterIcon from "../../components/ui/CenterIcon";
import AboutInfoSection from "./AboutInfoSection";
import MedhatLogo from "../../assets/medhatLogo";
import pattern from "../../assets/pattern.svg";

const AboutSection = () => {
    // const {scrolling}  = useContext

    const { token } = useContext(AuthContext);
    const { darkmode } = useContext(ThemeContext);
    if (token) {
        return <AboutInfoSection />;
    }
    return (
        <>
            <div className="bg-primary-container smooth relative">
                <Waves className="fill-khaki-100 dark:fill-[#1a1718] transform scale-y-50 origin-bottom" />
                <Waves className="fill-khaki-100 dark:fill-[#1a1718] transform scale-y-50 rotate-180 -translate-y-1/4 absolute w-full h-full inset-0" />
            </div>
            <section className="relative overflow-hidden dark:bg-[#1a1718] bg-khaki-100 clr-text-primary smooth">
                <div
                    className="right-0 top-0 bottom-0 h-full w-full z-0 absolute opacity-25 dark:opacity-20  smooth"
                    style={{
                        backgroundImage: "url(" + pattern + ")",
                        backgroundSize: "contain",
                        backgroundPosition: "center center",
                        backgroundRepeat: "repeat-both",
                    }}
                ></div>
                <Container className="h-full">
                    {/* <div>
                        <img
                            className="bottom-0 left-0 right-0 w-full z-0 absolute dark:brightness-[.1] smooth"
                            src={darkmode ? bg : bglight}
                            alt="bg-bottom"
                        />
                    </div> */}
                    <div className="flex-center-both w-full h-full relative space-y-5 flex-col pb-10">
                        <MedhatLogo className="max-w-2xl" />
                        <span className="text-sandy-500 dark:text-medhatBlue-200 before:opacity-50 dark:before:opacity-100 before:saturate-[.5] relative font-graph">
                            <h1 className="font-big font-w-bold text-center   smooth relative">
                                م / مدحت{" "}
                                <span className="relative text-sandy-500  saturation-color-with-dark dark:text-medhatBlue-50 smooth">
                                    رمضان
                                </span>
                            </h1>
                        </span>
                        {!token ? (
                            <Button
                                element="Link"
                                to="/register"
                                color="kashmir"
                                className="rounded- rounded-br-[100px] hover:rounded-br-[0px]  hover:rounded-tl-[100px] px-10 py-2"
                            >
                                انضم لينا دلوقتي !
                            </Button>
                        ) : (
                            ""
                        )}
                        {/* <div className="flex-col space-y-30">
                        </div> */}
                    </div>
                </Container>
            </section>
            <AboutInfoSection />
            {/* <Testimonials /> */}
        </>
    );
};

export default AboutSection;
