import React, { useEffect, useState } from "react";
import InputField from "../../components/form/elements/InputField";
import AdminContainer from "../../components/ui/AdminContainer";
import RemoteTable from "../../components/ui/RemoteTable";
import {
    isAdminToAdminNote,
    isAdminToAdminTags,
    isMultiYear,
} from "../../services/defaultSettings";
import { getYearPlaceHolder } from "../../services/yearSevices";
import { printFullDateTime } from "../../utils/time";
import { handleInputChange } from "../../services/formServices";
import http from "../../services/httpServices";
import Button from "../../components/ui/Button";
import { Link } from "react-router-dom";
import AdminToAdminNotesTableCell from "./AdminToAdminNotesTableCell";
import AdminToAdminTagsTableCell from "./AdminToAdminTagsTableCell";
import auth from "../../services/authServices";
import AddAdminToAdminTagsForm from "./AddAdminToAdminTagsForm";

const UsersTable = () => {
    const [adminToAdminUserTags, setAdminToAdminUserTags] = useState([]);

    const [editableData, setEditableData] = useState(null);
    const [isToEditData, setIsToEditData] = useState(false);

    const getAdminToAdminUserTags = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);

        const { data: response } = await http.get(`/api/admin_to_admin_user_tags/options`, config);
        setAdminToAdminUserTags(response);
    };

    const handleSuccess = (user, note) => {
        let newData = editableData.map((row) => {
            let admin_to_admin_user_tags = row.admin_to_admin_user_tags
                ? [note, ...row.admin_to_admin_user_tags]
                : [note];
            if (row.id === user.id) {
                return {
                    ...row,
                    admin_to_admin_user_tags,
                };
            } else {
                return row;
            }
        });
        setEditableData(newData);
        setIsToEditData(true);
    };

    useEffect(() => {
        getAdminToAdminUserTags();
    }, []);
    let columns = [
        {
            name: "التسلسل",
            reorder: true,
            selector: (row) => row.id,
            sortable: true,
            sortField: "id",
        },
        {
            name: "الاسم الكامل",
            reorder: true,
            selector: (row) => (
                <Link
                    className="block px-1 py-1 text-center underline"
                    element="Link"
                    to={`../user_profile/${row.id}`}
                >
                    {row.full_name}
                </Link>
            ),
            sortable: true,
            sortField: "full_name",
        },
        {
            name: "عدد مرات تغير الباسورد",
            reorder: true,
            selector: (row) => row.password_reset_count,
            sortable: true,
            sortField: "password_reset_count",
        },
        {
            name: "رقم الهاتف",
            reorder: true,
            selector: (row) => "0" + row.phone,
            sortable: true,
            sortField: "phone",
        },
    ];
    if (isAdminToAdminTags) {
        columns.push({
            name: "اضافة تاجات الأدمن",
            reorder: true,
            selector: (row) => (
                <AddAdminToAdminTagsForm
                    user={row}
                    afterSuccess={handleSuccess}
                    onTable={true}
                    parentAdminToAdminUserTags={adminToAdminUserTags}
                />
            ),
            minWidth: "400px",
            // format: (row) => <AdminToAdminNotesTableCell user={row} />,
        });
        columns.push({
            name: "تاجات الأدمن",
            reorder: true,
            selector: (row) => <AdminToAdminTagsTableCell user={row} />,
            // format: (row) => <AdminToAdminNotesTableCell user={row} />,
        });
    }
    if (isAdminToAdminNote) {
        columns.push({
            name: "ملاحظات الأدمن",
            reorder: true,
            selector: (row) => <AdminToAdminNotesTableCell user={row} />,
            // format: (row) => <AdminToAdminNotesTableCell user={row} />,
        });
    }
    columns = [
        ...columns,
        {
            name: "رقم هاتف ولي الأمر",
            reorder: true,
            selector: (row) => "0" + row.father_phone,
            sortable: true,
            sortField: "father_phone",
        },
        {
            name: "البريد الإلكتروني",
            reorder: true,
            selector: (row) => row.email,
            sortable: true,
            sortField: "email",
        },
    ];
    if (isMultiYear) {
        columns.push({
            name: "العام الدراسي",
            reorder: true,
            selector: (row) => row.year,
            format: (row) => getYearPlaceHolder(row.year),
            sortable: true,
            sortField: "year",
        });
    }

    columns = [
        ...columns,
        {
            name: "عدد الإشتراكات",
            reorder: true,
            selector: (row) => row.subscriptions_count,
            sortable: true,
            sortField: "subscriptions_count",
        },
        {
            name: "عدد الفواتير",
            reorder: true,
            selector: (row) => row.invoices_count,
            sortable: true,
            sortField: "invoices_count",
        },
        {
            name: "تاريخ انشاء الحساب",
            reorder: true,
            selector: (row) => row.created_at,
            format: (row) => printFullDateTime(row.created_at),
            sortable: true,
            sortField: "created_at",
        },
    ];

    const initialState = {
        full_name: "",
        phone: "",
        father_phone: "",
        email: "",
        government_id: 0,
    };

    const [filterData, setFilterData] = useState(initialState);

    const [data, setData] = useState(initialState);

    const [governments, setGovernments] = useState([]);

    async function getGovernments() {
        const { data } = await http.get("/api/governments");
        setGovernments(data);
    }
    useEffect(() => {
        getGovernments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AdminContainer>
            <div className="w-full space-y-6">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-6 w-full">
                    <InputField
                        id="full_name"
                        placeholder="اسم الطالب"
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                    <InputField
                        id="phone"
                        placeholder="رقم هاتف الطالب"
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                    <InputField
                        id="father_phone"
                        placeholder="رقم هاتف ولي الأمر"
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                    <InputField
                        id="email"
                        placeholder="البريد الإلكتروني"
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                    <InputField
                        id="government_id"
                        placeholder="المحافظة"
                        type="select"
                        options={[
                            {
                                value: 0,
                                text: "الكل",
                            },
                            ...governments,
                        ]}
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                        className="lg:col-span-2"
                    />
                </div>
                <Button
                    className="w-full"
                    color="blue"
                    onClick={() => {
                        setFilterData(data);
                    }}
                >
                    بحث
                </Button>
                <RemoteTable
                    api={`/api/users/paginate`}
                    isToEditData={isToEditData}
                    setIsToEditData={setIsToEditData}
                    editableData={editableData}
                    setEditableData={setEditableData}
                    columns={columns}
                    filterData={filterData}
                />
            </div>
        </AdminContainer>
    );
};

export default UsersTable;
