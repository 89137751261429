import React, { useContext } from "react";
import Button from "../../components/ui/Button";
import modal from "../../services/modalServices";
import AuthContext from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const SubscriptionMethodButton = () => {
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();
    return (
        <Button
            color="medhatBlue"
            onClick={() =>
                modal.message({
                    title: "طريقة الاشتراك",
                    text: `${
                        token
                            ? `هتنزل تحت هتختار الكورس اللي انت عاوز تشترك فيه`
                            : `دوس على "أنشئ حسابك الآن !" لو لسة معملتش حساب على المنصة و بتملى البيانات كويس و بعدين بتدوس علي إنشاء الحساب
                                   هيقولك تم إنشاء حسابك بنجاح بتروح للصفحة الرئيسية
                                    هتنزل تحت هتختار الكورس اللي انت عاوز تشترك فيه`
                    }

                            هتدوس علي "اشترك الآن" علي الكورس، و بعدين هتلاقي نفسك جوا صفحة الفاتورة هتدوس على "الذهاب للدفع", هتلاقي نفسك جوا صفحة الدفع هتختار طريقة الدفع اللي تناسبك 
                            
                            لو هتدفع من خلال فوري هيظهرلك الرقم المرجعي تروح تدفع بيه عند اي مكنة فوري تقوله عاوز ادفع علي الكود اللي ظهرلك على خدمة اسمها Fawry Pay 788
                            لو هتدفع من خلال الـ ڤيزا هتملا البيانات المطلوبة و بس
                            
                            بعد مـ تدفع هتلاقي الكورس اتفعلك اوتوماتك على 
                            المنصة علي طول

                            و هتلاقي مكتوب على الكورس : "انت مشترك بهذا الكورس"
                            
                            لو قابلتك اي مشكلة كلمنا على الواتساب و هنحللك مشكلتك في اسرع وقت ❤️`,
                    buttons: {
                        whatsapp: "الواتساب",
                        register: token ? "كورس الشهر الأول !" : "انشئ حسابك الآن !",
                        confirm: "حسنا",
                    },
                    callback: (e) => {
                        if (e === "whatsapp") {
                            window.open("https://wa.me/201224638206", "_blank");
                        } else if (e === "register") {
                            if (token) {
                                navigate("/course/1");
                            } else {
                                navigate("/register");
                            }
                        }
                        console.log(e);
                    },
                })
            }
        >
            طريقة الاشتراك
        </Button>
    );
};

export default SubscriptionMethodButton;
