import React from "react";
import CenterIcon from "../../components/ui/CenterIcon";
import SubscriptionMethodButton from "./SubscriptionMethodButton";
import SingleCovid from "../../assets/SingleCovide";

const AboutInfoSection = () => {
    return (
        <section className="md:p-10 py-10 px-5 relative">
            <div className="flex-center-both flex-col font-graph md:p-10 pt-10 pb-40 px-5 text-center space-y-5">
                <div className="font-w-bold font-h1">
                    الموقع الرسمي لمستر{" "}
                    <span className="relative before-fill-box text-medhatBlue-300 dark:text-sandy-600 inline-block">
                        <span className="relative clr-text-primary smooth">مدحت رمضان</span>
                    </span>
                </div>
                <div className="space-y-3">
                    <div className="font-h2">
                        <div>
                            بخبرة تعدي <span className="underline">الـ ٢٧ سنة</span> في تدريس مادة
                            الأحياء للثانوية العامة قدرنا نخّرج أكتر من
                            <span className="relative before-fill-box text-medhatBlue-300 dark:text-sandy-600  inline-block">
                                <span className="relative clr-text-primary smooth">١١٠,٠٠٠</span>
                            </span>{" "}
                            <span className="underline">طالب و طالبة</span>
                        </div>

                        <div>
                            بأعلى درجات في المادة, بالإضافة لأوائل الجمهورية و المحافظات لكل سنة.
                        </div>
                    </div>
                    <div className="font-h3">
                        <div>
                            <span className="font-w-bold">أهم حاجة عندنا;</span> إننا نفهمك المادة
                            <span className="underline text-medhatBlue-600">بالشرح المبسط</span>, و
                            نربطلك جزئيات المنهج ببعض,
                        </div>
                        <div>
                            و نحل معاك{" "}
                            <span className="underline text-medhatBlue-600">
                                أكبر قدر من الأسئلة
                            </span>{" "}
                            , مع{" "}
                            <span className="underline text-medhatBlue-600">
                                متابعة دورية اسبوعية
                            </span>{" "}
                            على كل محاضرة
                        </div>
                        <div>
                            توصلك لبر الآمان و تضمنلك{" "}
                            <span className="relative before-fill-box text-medhatBlue-300 dark:text-sandy-600  inline-block">
                                <span className="relative clr-text-primary smooth font-w-bold">
                                    <span>الـ ٦٠ في المادة</span>
                                </span>
                            </span>{" "}
                            زي كل طلابنا في <span className="underline">كل سنة.</span>
                        </div>
                        {/* <div>
                            و حل مع بعض{" "}
                            <span className="underline text-lime-500">
                                أكبر قدر ممكن من الأسئلة
                            </span>{" "}
                            من أسئلة النظام الجديد
                        </div> */}
                        {/* <div className="">
                            و متابعة دورية على كل محاضرة عشان تضمن{" "}
                            <span className="underline text-pink-500">درجتك النهائية</span> في
                            المادة{" "}
                            <span className="">
                                <span className="relative before-fill-box text-pink-300 dark:text-pink-900 smooth inline-block">
                                    <span className="relative smooth clr-text-primary">
                                        زي كل طلابنا السنين اللي فاتت !
                                    </span>
                                </span>
                            </span>
                        </div> */}
                    </div>
                    <div className="font-h3">
                        <div>
                            و خليك عارف{" "}
                            <span className="relative before-fill-box text-medhatBlue-300 dark:text-sandy-600 inline-block">
                                <span className="relative smooth clr-text-primary">
                                    إنك أول اهتمامتنا جوا و برا الدراسة
                                </span>
                            </span>{" "}
                            عشان كدة هنسهل عليك التواصل معانا,
                        </div>
                        <div>
                            <span>في حين قابلتك أي مشكلة</span>{" "}
                            <span className="font-w-bold">
                                "اتصل بينا أو ابعتلنا واتساب على نفس الرقم"
                            </span>
                        </div>
                        {/* <div>
                            إنك تلاقي كل حاجة{" "}
                            <span className="relative before-fill-box text-sandy-400 dark:text-lime-900 smooth inline-block">
                                <span className="relative smooth clr-text-primary">
                                    أنت محتاجها
                                </span>
                            </span>{" "}
                            في الدراسة أو حتى برا الدراسة و عشان
                        </div>
                        <div>
                            كدة ده رقمنا لو قابلتك أي مشكلة من أي نوع{" "}
                            <span className="relative before-fill-box text-pink-300 dark:text-pink-900 smooth inline-block">
                                <span className="relative smooth clr-text-primary">
                                    اتواصل معانا
                                </span>
                            </span>{" "}
                            على طول على
                        </div> */}
                        {/* <div>الرقم ده او ابعتلنا واتساب على نفس الرقم !</div> */}
                        <div className="py-2 font-h2 flex-center-both">
                            <span className="block relative before-fill-box text-medhatBlue-400 dark:text-sandy-600 py-2">
                                <a
                                    className="font-w-bold relative clr-text-primary smooth block"
                                    href="https://wa.me/201224638206"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <span className="flex pl-2 pt-1 pr-4 space-x-2 space-x-reverse">
                                        <span className="en inline-block">0122 463 8206</span>
                                        <CenterIcon icon="icon-park-twotone:phone-call" />{" "}
                                    </span>
                                    {/* <div className="flex  space-x-2 space-x-reverse">
                                            
                                        </div> */}
                                </a>
                            </span>
                        </div>
                    </div>
                    <div className="font-h3">
                        {/* <div className="font-w-bold underline">طريقة الاشتراك</div> */}
                        <SubscriptionMethodButton />
                    </div>
                </div>
            </div>
            <div className="absolute top-10 -z-10 right-10 max-w-[150px]">
                <SingleCovid />
            </div>
            <div className="absolute bottom-10 -z-10 left-10 max-w-[350px]">
                <SingleCovid />
            </div>

            <div className="absolute left-[40%] hidden md:block top-20 w-[450px] blur-sm -z-10">
                <SingleCovid />
            </div>
        </section>
    );
};

export default AboutInfoSection;
