import React, { useState } from "react";
import auth from "../../services/authServices";

import http from "../../services/httpServices";
import modal from "../../services/modalServices";
import AddAdminToAdminTagsForm from "./AddAdminToAdminTagsForm";
import AdminToAdminUserTag from "./AdminToAdminUserTag";

const AdminToAdminTagsForm = ({ user = { id: 0 }, afterSuccess = () => null }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleDelete = async (id) => {
        setIsLoading(true);

        modal.message({
            title: "هل انت متأكد",
            text: `هل انت متأكد من حذف الملاحظة؟`,
            icon: "info",
            // button: "تأكيد",
            buttons: {
                confirm: "تأكيد",
                cancel: "إلغاء",
            },
            callback: async (e) => {
                if (e && e !== "cancel") {
                    const adminToken = auth.getAdminToken();
                    const config = auth.getAdminAuthConfig(adminToken);
                    try {
                        // eslint-disable-next-line no-unused-vars
                        const { data: response } = await http.delete(
                            `/api/users/${user.id}/admin_to_admin_user_note/${id}`,
                            config
                        );

                        modal.message({
                            title: `عملية ناجحة`,
                            text: "تم حذف الملاحظة بنجاح",
                            callback: () => {
                                setIsLoading(false);
                                afterSuccess();
                            },
                        });
                    } catch (error) {
                        setIsLoading(false);
                    }
                } else {
                    setIsLoading(false);
                }
            },
        });
    };

    return (
        <>
            {/*  <AdminForm
             onSubmit={handleSubmit}
             isLoading={isLoading}
             buttonText="اضافة تاجات للأدمن"
             className="space-y-4 py-0 pb-16"
         > */}
            <div className="space-y-4 py-0 pb-16 flex flex-col w-full max-w-lg mx-auto">
                <div className="font-h1 text-center font-w-bold">تاجات الادمن</div>
                <div className="pb-4 space-y-5">
                    {/* <div className="w-full px-2 py-3 bg-yellow-200 text-slate-600 rounded-md shadow-md text-center">
                    
                </div> */}
                    {user.admin_to_admin_user_tags && user.admin_to_admin_user_tags.length > 0 ? (
                        user.admin_to_admin_user_tags.map((note) => (
                            <AdminToAdminUserTag
                                key={note.id}
                                note={note}
                                handleDelete={handleDelete}
                                isLoading={isLoading}
                            />
                        ))
                    ) : (
                        <div className="w-full px-2 py-3 bg-yellow-200 text-slate-600 rounded-md shadow-md text-center">
                            لا توجد تاجات سابقة
                        </div>
                    )}
                </div>
                <AddAdminToAdminTagsForm afterSuccess={afterSuccess} user={user} />
            </div>

            {/* </AdminForm> */}
        </>
    );
};

export default AdminToAdminTagsForm;
