import React, { useContext, useEffect, useState } from "react";

import ScrollingContext from "../../context/ScrollingContext";

import { getScrollingPercentage, windowHeight } from "../../services/scrollingServices";

import Waves from "./Waves";

import "./sectionHead.css";

const SectionHead = ({
    className = "bg-sandy-400 dark:bg-medhatBlue-800 smooth saturate-50 text-slate-100",
    title = "الكورسات",
    isWave = true,
}) => {
    const [percentage, setPercentage] = useState(0);
    const { scrolling } = useContext(ScrollingContext);

    useEffect(() => {
        const yearsHeader = document.querySelector(".home-years__head");

        if (scrolling !== undefined && yearsHeader) {
            const yearsHeaderHeight = parseFloat(yearsHeader.style.height) || 0;

            let { percentage: percent } = getScrollingPercentage(
                scrolling,
                yearsHeader.getBoundingClientRect().top + scrolling,
                (windowHeight + yearsHeaderHeight) / 2
            );
            setPercentage(percent);
        }
    }, [scrolling]);

    return (
        <div className="pt-20">
            <div className={`home-years__head ${className} relative pb-16 pt-8`}>
                <div className="scale-y-[.25] rotate-180 -translate-y-[37.5%] absolute top-0 w-full inset-x-0">
                    <Waves className="fill-primary-container" />
                </div>
                <div className="bg-rose-500 relative"></div>
                {/* <div className="bg-primary-container smooth relative">
                <Waves className="fill-[#d5cfc6] dark:fill-[#663b49] transform scale-y-50 origin-bottom" />
            </div> */}
                <div className={`relative px-10 overflow-hidden ${isWave ? " pt-10 " : "py-10"}`}>
                    <div className="home-years__title font-big py-5 font-w-bold relative">
                        <div
                            className="home-years-title-bg__container"
                            style={{ transform: `translateX(${-100 * (1 - percentage)}%)` }}
                        >
                            <div className="home-years-title__bg font-bigmax text-slate-100">
                                {title}
                            </div>
                        </div>

                        <div className="home-years-title__content relative z-10">{title}</div>
                    </div>
                </div>
                <div className="scale-y-[.25] translate-y-[37.5%] absolute bottom-0 w-full inset-x-0">
                    <Waves className="fill-primary-container" />
                </div>
            </div>
        </div>
    );
};

export default SectionHead;
