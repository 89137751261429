import React, { useEffect, useState } from "react";

import { ScrollingProvider } from "../../../context/ScrollingContext";

// import SectionHead from "../../../components/ui/SectionHead";

// import year1 from "../../../../assets/year-2.jpeg";
// import Waves from "../../../components/ui/Waves";
import Categorie from "./Categorie";
import auth from "../../../services/authServices";
import http from "../../../services/httpServices";
import LoadingIcon from "./../../../components/ui/LoadingIcon";
import CoursesWithCategories from "./CoursesWithCategories";
import { getYearPlaceHolder, years } from "../../../services/yearSevices";

const CoursesSection = ({ title = false, year = 3, params = "" }) => {
    // const { token } = useContext(AuthContext);
    return (
        <div className="relative mb-20 overflow-hidden" id="courses">
            {/* <ScrollingProvider>
                <SectionHead title={title ? title : "الكورسات"} />
            </ScrollingProvider> */}

            <div className="py-20">
                <CoursesWithCategories
                    titleFirst="كورسات"
                    titleLast={getYearPlaceHolder(year)}
                    api={`${
                        year == 3
                            ? `/api/courses_category/years/${year}/courses?subject=${params}`
                            : `/api/courses_category/years/${year}/courses`
                    } `}
                />
            </div>
        </div>
    );
};

export default CoursesSection;
