import React, { useContext } from "react";

import { printFullDateTime } from "../../utils/time";
import AuthContext from "../../context/AuthContext";
import CenterIcon from "../../components/ui/CenterIcon";
import LoadingIcon from "../../components/ui/LoadingIcon";

const AdminToAdminUserTag = ({ note = null, handleDelete = () => null, isLoading = false }) => {
    const { admin } = useContext(AuthContext);
    return (
        <div className="w-full px-2 py-3 bg-yellow-100 dark:bg-yellow-900 bg-opacity-50 dark:bg-opacity-30 smooth rounded-md shadow-md flex-center-both flex-col space-y-3 relative">
            <div className="w-full text-start font-smaller text-slate-400 dark:text-slate-400 smooth">
                {printFullDateTime(note.created_at)}
            </div>
            <div className="pt-3 text-slate-900 dark:text-slate-100 smooth flex-center-both flex-wrap gap-4">
                {note.admin_to_admin_user_tags.map((tag) => {
                    return (
                        <div className="bg-cyan-500 text-white rounded-md px-3 py-1 shadow-md flex-">
                            {tag.name}
                        </div>
                    );
                })}
            </div>
            <div className="w-full text-end font-small text-slate-400 dark:text-slate-400 smooth">
                تمت الإضافة بواسطة : <span className="font-w-bold">{note.added_by}</span>
            </div>
            {admin.super_admin || admin.email === note.added_by ? (
                <div
                    className="absolute top-3 left-3 !mt-0"
                    onClick={() => !isLoading && handleDelete(note.id)}
                >
                    <div className="font-h3 bg-slate-500 bg-opacity-50 hover:bg-opacity-100 smooth cursor-pointer hover-shadow rounded-md p-2 text-white">
                        {isLoading ? <LoadingIcon /> : <CenterIcon icon={"ic:twotone-delete"} />}
                    </div>
                </div>
            ) : (
                ""
            )}
        </div>
    );
};

export default AdminToAdminUserTag;
